import { datadogRum } from '@datadog/browser-rum'
import packageInfo from '../../package.json'

export const setupDatadog = (env: string) => {
  init(env)
  datadogRum.startSessionReplayRecording()
}

const init = (env: string) => {
  datadogRum.init({
    applicationId: '8db37b49-2dc5-4784-8c50-1a750e2745de',
    clientToken: 'pub870b46485c452c7dc89689a53145b77a',
    site: 'datadoghq.com',
    service: 'oes-trial-agreement-webview-rum',
    env: env,
    version: packageInfo.version,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    // 個人情報を取り扱うので全ての文字列をマスクする
    // https://docs.datadoghq.com/ja/real_user_monitoring/session_replay/privacy_options/
    defaultPrivacyLevel: 'mask',
  })
}
